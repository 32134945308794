import { createRouter, createWebHashHistory } from 'vue-router'
import { Trans } from '@/plugins/translation'
function load(component) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`)
}

const routes = [
  {
    path: '/:lang',
    name: 'Accueil',
    component: load('Accueil'),
    beforeEnter: Trans.routeMiddleware,
    props: true,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: load('Home'),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'checkings',
            name: 'CheckingsList',
            component: load('CheckingsList'),
            children: [
              {
                path: ':checkingId',
                name: 'Checking',
                component: load('CheckingView'),
              }
            ]
          }
        ]
      },
      {
        path: 'sign-in',
        name: 'SignIn',
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return Trans.getUserSupportedLang();
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
