import 'mdb-vue-ui-kit/css/mdb.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/translation'
import helpers from '@/helpers'

const app = createApp(App)

app.use(router).use(store).use(i18n)

app.config.globalProperties.$i18nRoute = Trans.i18nRoute.bind(Trans)
app.config.globalProperties.$helpers = { ...helpers }

app.mount('#app')