import { createStore } from "vuex";
// const axios = require('axios');
// const eecheckApp = axios.create({
//   baseURL: 'https://api.eecheck.app/'
// });
import { login, getAll } from './sim.json'
// import router from "@/router";

export default createStore({
  state: {
    // api
    api: {
      url: "https://api.eecheck.app/",
    },

    // app
    app: {
      windowSize: null,
      clickPath: null,
      theme: null,
      defaultTheme: null,
    },

    //person
    person: {},

    // checkings
    checkingsList: []
  },
  mutations: {
    mutateKey(state, payload) {
      let mutate = payload.sKey;
      delete payload.sKey;
      if (Array.isArray(state[mutate]) && !Array.isArray(payload.body)) {
        if (state[mutate].length > 0) {
          for (let elem of state[mutate]) {
            if (JSON.stringify(elem) == JSON.stringify(payload.body)) return;
          }
        }
        state[mutate].push(payload.body);
      } else {
        state[mutate] = payload.body;
      }
    },
  },
  actions: {
    mutateStore(context, payload) {
      context.commit(payload.fct, payload.value || null);
    },
    async login(context) {
      // eecheckApp.post(context.state.api.url + "auth/login", payload)
      //   .then((res) => { context.mutateKey('person', res) })
      context.state.person = login.data
    },
    async getAll(context) {
      context.state.checkingsList = getAll.data
    }
  },
  getters: {},
  modules: {},
});
